<template>
  <div class="h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <van-nav-bar
      title="银行卡列表"
      left-arrow
      @click-left="goBack"
      right-text="重新绑定"
      @click-right="$router.push('/bindcard')"
    />




    <div class=" m_t_16 ">


      <div class="pos_rel m_0_16">
        <!-- 背景 -->
      <van-image
        class="w_100_per"
        :src="require('@/assets/bankcard.png')"
      />
      <div class="pos_abs l_0 t_0 w_100_per ">
        <van-cell :title="form.bankName || '-'" :border="false" title-class="flex_1 font_16 col_fff" class="m_t_16" style="background: none">

          <template>

            <van-tag v-if="form.bindStatus == 1"  size="large"  class="selected-tag"  style="background: none"  type="success" >绑卡成功</van-tag>

            <van-tag v-if="form.bindStatus == 2"  size="large"  class="selected-tag"  style="background: none"  type="danger">绑卡失败</van-tag>

            <van-tag v-if="form.bindStatus == 3"  size="large"  class="selected-tag"  style="background: none"  type="warning">绑卡中</van-tag>

          </template>
        </van-cell>

        <!-- 留白 -->
        <div class="h_56"></div>

        <!-- 卡号 -->
        <div class="dis_flex ju_con_cen col_fff font_24 font_bold">
          {{ form.bankAccount || '-' }}
        </div>



        <van-cell :title="form.bankAccountName || '-'" :border="false" title-class="flex_1 font_16 col_fff m_l_16"  style="background: none">
        </van-cell>
      </div>
      </div>
    </div>


    <!-- 留白 -->
    <div class="h_56"></div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';

/*import { nationalBrokerProfileBankCardInfo } from '@/api/index';*/

export default {
  components: {},
  data() {
    return {
      form: {bankName:"中国银行",bindStatus:1,bankAccount:"6217003760007735541",bankAccountName:"测试"},

      //
    };
  },
  computed: {},
  mounted() {
    this.getDetail();

    //
  },
  methods: {
    // 获取 详情
    async getDetail() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        // this.form = await nationalBrokerProfileBankCardInfo();
        Toast.clear();

        //
      } catch (err) {
        Toast(err);
      }

      //
    },


    goBack() {
      this.$router.go(-1);
    },

    //
  },
};
</script>

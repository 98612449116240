var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9"},[_c('van-nav-bar',{attrs:{"title":"产品列表","left-arrow":""},on:{"click-left":_vm.goBack}}),_c('van-pull-refresh',{staticClass:"h_100_vh over_y_auto bg_col_F5F7F9",on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"immediate-check":false,"finished":_vm.finished,"finished-text":_vm.list.length ? '没有更多了' : '暂无数据'},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"m_t_16"},[_c('van-cell-group',{attrs:{"inset":""}},[_c('van-card',{staticStyle:{"background":"#FFF","margin":"16px 0"},attrs:{"thumb":item.productLogoUrl || '-',"thumb-link":'/product/detail?id='+item.id},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"font_bold font_14"},[_vm._v(_vm._s(item.productName))])]},proxy:true},{key:"tags",fn:function(){return _vm._l(([
              {
                label: '最高额度',
                value: item.maximumAmount
                  ? `${item.maximumAmount / 10000}万`
                  : '-',
              },
              {
                label: '最高期限',
                value: item.applyLoanTermStr
                  ? `${Math.max(...item.applyLoanTermStr.split(','))}期`
                  : '-',
              },
              {
                label: '最低月利率',
                value: item.interestRate ? `${item.interestRate}%` : '-',
              },
              {
                label: '产品类型',
                value: item.productTypeStr || '-',
              },
            ]),function(tag,i){return _c('van-tag',{key:i,staticClass:"selected-tag",attrs:{"plain":"","size":"medium","type":"primary"}},[_vm._v(_vm._s(tag.label)+": "+_vm._s(tag.value))])})},proxy:true},{key:"price",fn:function(){return [_c('van-tag',{staticClass:"selected-tag",attrs:{"size":"mini","mark":"","type":"danger"}},[_vm._v("返")]),_c('span',{staticClass:"col_FF5252 font_14 m_t_16"},[_vm._v(_vm._s(item.productRebate)+"%")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"m_t_16"},[_c('van-button',{attrs:{"size":"small","plain":"","type":"info"},on:{"click":function($event){return _vm.share(item)}}},[_vm._v(" 分享 ")]),_c('van-button',{attrs:{"size":"small","type":"info"},on:{"click":function($event){return _vm.detail(item)}}},[_vm._v(" 详情 ")])],1)]},proxy:true}],null,true)})],1)],1)}),0)],1),_c('van-popup',{model:{value:(_vm.shareDialogShow),callback:function ($$v) {_vm.shareDialogShow=$$v},expression:"shareDialogShow"}},[_c('div',{staticClass:"w_88_vw"},[_c('van-swipe',_vm._l((_vm.shareImgSrcArr),function(item,index){return _c('van-swipe-item',{key:index},[_c('img',{staticClass:"dis_flex",attrs:{"src":item},on:{"click":function($event){_vm.shareDialogShow = false}}})])}),1)],1)]),_c('canvas',{ref:"myCanvas",staticClass:"pos_fixed l_200_vw t_0",attrs:{"width":_vm.shareImgWidth,"height":_vm.shareImgHeight}}),(_vm.shareQrcodeText)?_c('VueQr',{ref:"qrCode",staticClass:"pos_fixed l_200_vw t_0",attrs:{"text":_vm.shareQrcodeText,"callback":_vm.qrCallback}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <van-nav-bar
      title="经纪人管理"
      left-arrow
      @click-left="goBack"
    />

    <van-tabs v-model="tabIndex" color="#0A82E6">
      <van-tab
        v-for="(item, index) in tabArr"
        :key="index"
       >

        <template #title>

          <div>
            <van-badge v-show="index>0" :content="index==1?columnAmount(statisticInfo.leaderCount, { fixed: 0 }):columnAmount(statisticInfo.leaderCount, { fixed: 0 })" class="pos_abs t_5 r_5 " />
            <div class="font_12 m_t_8 font_bold" >{{ item.label }} </div>
          </div>
        </template>


        <van-search
          v-model="filterForm.keyword"
          show-action
          placeholder="请输入姓名"
          @search="
              $refs['pullRefresh'].scrollTop = 0;
              onRefresh();"
          v-show="tabIndex > 0"
        >

          <template #action>
            <div @click="
              $refs['pullRefresh'].scrollTop = 0;
              onRefresh();">搜索</div>
          </template>
        </van-search>
      </van-tab>
    </van-tabs>

    <div class="m_t_16" v-show="tabIndex == 0"  v-for="(item, index) in [
                {
                  label: '招募统计（城市合伙人）',
                  iconUrl: 'point-gift',
                  color: 'icon_col_FF7043',
                  children: [
                    {
                      label: '今日新增',
                      value: columnAmount(statisticInfo.leaderToDayCount, {
                        fixed: 0,
                      }),
                      iconUrl: 'new',
                      color: 'icon_col_2396F5',
                    },
                    {
                      label: '本周新增',
                      value: columnAmount(statisticInfo.leaderWeekDayCount, {
                        fixed: 0,
                      }),
                      iconUrl: 'hot',
                      color: 'icon_col_FF0000',
                    },
                    {
                      label: '本月新增',
                      value: columnAmount(
                        statisticInfo.leaderMonthWeekDayCount,
                        { fixed: 0 }
                      ),
                      iconUrl: 'expand',
                      color: 'icon_col_66BB6A',
                    },
                  ],
                },
                {
                  label: '招募统计（经纪人）',
                  iconUrl: 'bookmark',
                  color: 'icon_col_A674E3',
                  children: [
                    {
                      label: '今日新增',
                      value: columnAmount(statisticInfo.brokerToDayCount, {
                        fixed: 0,
                      }),
                      iconUrl: 'new',
                      color: 'icon_col_2396F5',
                    },
                    {
                      label: '本周新增',
                      value: columnAmount(statisticInfo.brokerWeekDayCount, {
                        fixed: 0,
                      }),
                      iconUrl: 'hot',
                      color: 'icon_col_FF0000',
                    },
                    {
                      label: '本月新增',
                      value: columnAmount(
                        statisticInfo.brokerMonthWeekDayCount,
                        { fixed: 0 }
                      ),
                      iconUrl: 'expand',
                      color: 'icon_col_66BB6A',
                    },
                  ],
                },
              ]"
         :key="index">

      <van-cell-group   inset  >
        <van-cell :icon="item.iconUrl" :border="false" :class="item.color" :title="item.label" ></van-cell>
        <van-grid :column-num="item.children.length">
          <van-grid-item   v-for="(lab,i) in item.children" :class="lab.color"  :key="i" :icon="lab.iconUrl" :text="lab.label" :badge="lab.value" />
        </van-grid>
      </van-cell-group>
    </div>

    <!-- 经纪人招募排行榜 -->
    <div class="m_t_16" v-show="tabIndex == 0">
      <van-cell-group   inset  >
        <van-cell icon="label"  class="icon_col_FF5252" title="经纪人招募排行榜 Top10" ></van-cell>
        <van-cell icon="manager" :border="false"
                  v-for="(item, index) in statisticInfo.topList || []"
                  :key="index" >
          <template #icon>
            <van-circle
              :rate="100"
              size="20px"
              :color="index==0?'#FF0000':index==1?'#F5A227':index==2?'#66BB6A':'#999'"
              :layer-color="index==0?'#FF0000':index==1?'#F5A227':index==2?'#66BB6A':'#999'"
              :fill= "index==0?'#FF0000':index==1?'#F5A227':index==2?'#66BB6A':'#999'"
              :text="index+1+''"
              class="m_t_2 circle_text_color_FFF"
            />
          </template>

          <template #title>
            <div class="m_l_8">{{ item.brokerName }}</div>
          </template>

          <template>
            <sapn class="col_333">{{ item.inviteeCount }}</sapn>
          </template>
        </van-cell>
      </van-cell-group>
    </div>



    <!-- 上下拉 -->
    <div ref="pullRefresh" class="flex_1 h_1 over_y_auto bg_col_F5F7F9">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="list.length ? '没有更多了' : null"
          @load="onLoad"
        >

          <!-- 城市合伙人|经纪人 -->
          <div v-show="tabIndex > 0" class="m_t_16" v-for="(item, index) in list" :key="index" >

            <van-cell-group   inset >
              <van-cell   :icon="tabIndex==1?'manager':'friends'" :border="false"  :title="item.brokerName" value-class="col_333"  class="icon_col_2396F5"  >
                <template>
                  <div class=" col_333">
                    <span class="font_bold">{{
                      item.brokerMobile | concealPhoneNumb
                    }}</span>
                  </div>
                </template>
              </van-cell>

              <van-cell >
                <template #title>
                  <div v-show="tabIndex == 1">
                    邀请人数：<span class="font_bold">{{
                      columnAmount(item.ownerCount, { fixed: 0 })
                    }}</span>
                  </div>
                  <div v-show="tabIndex == 2">
                    累计收益：<span class="font_bold">{{
                      columnAmount(item.profitIncome)
                    }}</span>
                  </div>
                </template>

                <template>
                  <div v-show="tabIndex == 2" class=" col_333">
                    订单数量：<span class="font_bold">{{
                      columnAmount(item.orderCount, { fixed: 0 })
                    }}</span>
                  </div>
                </template>
              </van-cell>


            </van-cell-group>



            <!-- 缺省 -->
            <van-empty  v-if="!list.length" description="暂无信息" class="empty-info m_t_32" />

            <!--  -->
          </div>

          <!--  -->
        </van-list>
      </van-pull-refresh>

      <!--  -->
    </div>


    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import { concealPhoneNumb, columnAmount } from '@/utils/tools';

import {
  nationalBrokerProfileBrokerStatisticsPagePost,
  nationalBrokerProfileRecruitStatistics,
} from '@/api/index';

export default {
  components: {
  },
  data() {
    return {
      tabIndex: 0,
      statisticInfo: {topList:[{ brokerName:'测试',inviteeCount:52},
          { brokerName:'测试',inviteeCount:52},
          { brokerName:'测试',inviteeCount:52},
          { brokerName:'测试',inviteeCount:52},
          { brokerName:'测试',inviteeCount:52},
          { brokerName:'测试',inviteeCount:52},
          { brokerName:'测试',inviteeCount:52},
          { brokerName:'测试',inviteeCount:52},
          { brokerName:'测试',inviteeCount:52},
          { brokerName:'测试',inviteeCount:52},
        ]},
      filterForm: {},

      /**
       * 列表
       */
      list: [{
        brokerName:"测试",
        brokerMobile:"15823336950",
        ownerCount:20,
        profitIncome:156548,
        orderCount:13245,
      },{
        brokerName:"测试",
        brokerMobile:"15823336950",
        ownerCount:20,
        profitIncome:156548,
        orderCount:13245,
      }],
      pageNum: 1,
      pageSize: 10,

      /**
       * 上下拉刷新
       */
      refreshing: false,
      loading: false,
      finished: false,

      //
    };
  },
  filters: {
    concealPhoneNumb,
  },
  computed: {
    // 选项卡
    tabArr: () => [
      {
        label: '招募统计',
      },
      {
        label: '城市合伙人',
      },
      {
        label: '经纪人',
      },
    ],

    //
  },
  mounted() {
    this.onRefresh();

    //
  },
  methods: {
    columnAmount,

    // 上拉加载
    onLoad() {
      this.getList({ pageNum: parseInt(this.pageNum) + 1 });
    },

    // 下拉刷新
    onRefresh() {
      switch (this.tabIndex) {
        case 0:
         // this.list = [];
          this.getStatisticInfo();
          break;
        default:
          this.getList({ pageNum: 1 });
      }
    },

    // 获取 列表
    async getList({ pageNum = 1, pageSize = this.pageSize }) {
      try {
        if (pageNum == 1) {
          Toast.loading({
            duration: 10 * 1000,
            message: '请稍后..',
            forbidClick: true,
          });
        }

        let params = {
          current: pageNum,
          size: pageSize,
          whetherLeaderStatus: ['LEADER', 'LEADER', 'BROKER'][this.tabIndex],
        };
        if ([1, 2].includes(this.tabIndex)) {
          params = { ...params, ...this.filterForm };
        }

        // const data = await nationalBrokerProfileBrokerStatisticsPagePost(
        //   params
        // );
        Toast.clear();
        this.refreshing = this.loading = false;

        // const len = data.records.length;
        //
        // this.list =
        //   data.current == 1 ? data.records : [...this.list, ...data.records];
        // this.finished = len < pageSize;
        this.pageNum =
          this.list.length > 0
            ? Math.ceil(this.list.length / this.pageSize)
            : 1;

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 获取 统计
    async getStatisticInfo() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });
        //
        // this.statisticInfo = await nationalBrokerProfileRecruitStatistics();


        Toast.clear();

        this.refreshing = this.loading = false;
        this.finished = true;

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    goBack() {
      this.$router.go(-1);
    },
    //
  },
};
</script>
<style>
.circle_text_color_FFF .van-circle__text{
  color:#FFFFFF;
  text-align: center;
  padding:0px
}
</style>

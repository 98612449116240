<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <van-nav-bar
      title="邀请记录"
      left-arrow
      @click-left="goBack"
    />

      <van-grid column-num="2">
        <van-grid-item icon="friends" class="icon_col_2396F5"  text="邀请数量" :badge="columnAmount(statisticInfo.total, { fixed: 0 })" />
        <van-grid-item icon="new" class="icon_col_66BB6A"  text="今日邀请" :badge="columnAmount(statisticInfo.todayTotal, { fixed: 0 })" />
      </van-grid>


    <!-- 上下拉 -->
    <div ref="pullRefresh" class="flex_1 over_y_auto h_1 bg_col_F5F7F9">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="list.length ? '没有更多了' : null"
          @load="onLoad"
        >

          <!-- 城市合伙人|经纪人 -->
          <div  class="m_t_16"  >

            <van-cell-group   inset  >
              <van-cell  v-for="(item, index) in list" :key="index" :icon="'friends'"   :title="item.brokerName" value-class="col_333"  class="icon_col_2396F5"  >

                <template #label>
                   <span class="font_bold col_666">{{
                       item.registeredTime
                     }}</span>
                </template>
                <template>
                  <div class=" col_333">
                    <span class="font_bold">{{
                        item.brokerMobile | concealPhoneNumb
                      }}</span>
                  </div>
                </template>
              </van-cell>
            </van-cell-group>



            <!-- 缺省 -->
            <van-empty  v-if="!list.length" description="暂无信息" class="empty-info m_t_32" />

            <!--  -->
          </div>

          <!--  -->
        </van-list>
      </van-pull-refresh>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import { columnAmount } from '@/utils/tools';

import {
  nationalBrokerProfileBrokerIdInviteePage,
  nationalBrokerProfileBrokerIdTodayInviteeCount,
} from '@/api/index';

export default {
  components: {},
  data() {
    return {
      userInfo: {},
      statisticInfo: {},

      /**
       * 列表
       */
      list: [{brokerName:"测试",brokerMobile:"15823336950",registeredTime:"2023-06-12 00:00:00"},{brokerName:"测试",brokerMobile:"15823336950",registeredTime:"2023-06-12 00:00:00"}],
      pageNum: 1,
      pageSize: 10,

      /**
       * 上下拉刷新
       */
      refreshing: false,
      loading: false,
      finished: false,

      //
    };
  },
  computed: {},
  mounted() {
    if (localStorage.userInfo) {
      const u = JSON.parse(localStorage.userInfo);
      this.userInfo = u;
    }

    // this.getTodayInviteeCount();
    //
    // this.getList({ pageNum: 1 });

    //
  },
  methods: {
    columnAmount,

    // 上拉加载
    onLoad() {
      this.getList({ pageNum: parseInt(this.pageNum) + 1 });
    },

    // 下拉刷新
    onRefresh() {
      this.getTodayInviteeCount();
      this.getList({ pageNum: 1 });
    },

    // 获取 列表
    async getList({ pageNum = 1, pageSize = this.pageSize }) {
      try {
        if (pageNum == 1) {
          Toast.loading({
            duration: 10 * 1000,
            message: '请稍后..',
            forbidClick: true,
          });
        }

        const params = {
          current: pageNum,
          size: pageSize,
        };

        const data = await nationalBrokerProfileBrokerIdInviteePage(
          this.userInfo.brokerId,
          params
        );
        Toast.clear();
        this.refreshing = this.loading = false;

        const len = data.records.length;

        this.list =
          data.current == 1 ? data.records : [...this.list, ...data.records];
        this.finished = len < pageSize;
        this.pageNum =
          this.list.length > 0
            ? Math.ceil(this.list.length / this.pageSize)
            : 1;

        this.statisticInfo = {
          ...this.statisticInfo,
          total: data.total,
        };

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 获取 今日邀请 数量
    async getTodayInviteeCount() {
      const data = await nationalBrokerProfileBrokerIdTodayInviteeCount(
        this.userInfo.brokerId
      );

      this.statisticInfo = {
        ...this.statisticInfo,
        todayTotal: data,
      };

      //
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<template>

  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <van-nav-bar
      title="订单详情"
      left-arrow
      @click-left="goBack"
    />



    <van-tabs type="card" class="m_t_16" color="#1989fa" animated sticky>
      <van-tab title="信息">
        <div class="m_t_16">
        <van-cell-group  inset >
          <InfoList :form="form" />
        </van-cell-group>
        </div>
      </van-tab>

      <van-tab title="时间轴">

          <div class="m_t_16">
            <van-cell-group  inset >
              <Timeline :form="form" />
          </van-cell-group>
          </div>
      </van-tab>
    </van-tabs>


    <!-- 留白 -->
    <div class="h_56"></div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';

import { nationalBrokerProfileOrderOrderNo } from '@/api/index';
import BottomTabBar from "../../../components/BottomTabBar";

export default {
  components: {
    BottomTabBar,
    InfoList: () => import('./components/InfoList.vue'),
    // // TEMP DEPRECATED
    Timeline: () => import('./components/Timeline.vue'),
  },
  data() {
    return {
      orderNo: null,

      tabIndex: 0,
      form: {},

      //
    };
  },
  computed: {},
  mounted() {
    const query = this.$route.query;
    if (!query.orderNo) {
      alert('缺少必须传参');
      return;
    }
    this.orderNo = query.orderNo;

    this.getDetail();

    //
  },
  methods: {

    goBack() {
      this.$router.go(-1);
    },
    // 获取 详情
    async getDetail() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        this.form = await nationalBrokerProfileOrderOrderNo(this.orderNo);

        Toast.clear();

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    //
  },
};
</script>

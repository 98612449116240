<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <!-- Banner -->
    <van-swipe class="my-swipe" autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in bannerItems" :key="index">
              <img :src="item.image" />
            </van-swipe-item>
    </van-swipe>



      <div class="p_16_0">
      <!-- 数据统计 -->
        <div>
        <van-cell-group inset>
          <van-grid :column-num="stats.length" >
            <van-grid-item v-for="(item, index) in stats" :key="index"     :badge="item.value>999?'999+':item.value" >

              <template #icon>
                <div class="font_12 m_t_8 font_bold col_2396F5" >{{item.title}}</div>
              </template>

            </van-grid-item>
          </van-grid>
        </van-cell-group>
        </div>



        <div class="m_t_16">
          <van-cell-group   inset >
<!--            <van-cell  is-link  @click="recruitGovernor" >
              <template #title>
                <van-icon name="invitation" class="col_FF5252" size="18px"   />  <span class="font_218">  邀请城市合伙人</span>
              </template>
              <template>
                <van-icon class="col_2396F5" size="18px" name="share" />
              </template>
            </van-cell>

            <van-cell  is-link  @click="share" >
              <template #title>
                <van-icon name="fire" class="col_FF5252" size="18px"   />  <span class="font_218">  邀请经纪人</span>
              </template>
              <template>
                <van-icon class="col_2396F5" size="18px" name="share" />
              </template>

            </van-cell>-->

            <van-cell  is-link  @click="goPromoteCustomer" >
              <template #title>
                <van-icon name="award" class="col_FF5252" size="18px"   />  <span class="font_218">  推广客户赚钱</span>
              </template>
              <template>
                <van-icon class="col_2396F5" size="18px" name="share" />
              </template>

            </van-cell>
          </van-cell-group>
        </div>

        <div class="m_t_16">
            <van-cell-group  ref="pullRefresh"  inset >


              <van-cell icon="shop-o" title="订单列表"  is-link >
                <template #default>
                  <span @click="orderpPage">更多</span>
                </template>
              </van-cell>

                <!-- 项 -->
                <ItemOrder
                  v-for="(item, index) in orderList"
                  :key="index"
                  :item="item"
                  :bor_solid="true"
                  class="m_t_16 m_0_8"
                  @handleDetail="
                    () => {
                      window.sessionStorage.indexScrollTop =
                        $refs['pullRefresh'].scrollTop;
                    }
                  "
                />


<!--              <van-loading  size="24px" vertical>加载中...</van-loading>-->
              <!-- 缺省 -->
              <van-empty  v-if="!orderList.length"  description="暂无订单" class="empty-info" ></van-empty>
            </van-cell-group>
        </div>

    </div>

    <div class="h_40"></div>

    <BottomTabBar />


  </div>
</template>

<script>
import ItemOrder from "@/components/ItemOrder.vue";
import BottomTabBar from "@/components/BottomTabBar.vue";

export default {
  components: {BottomTabBar, ItemOrder},
  data() {
    return {
      bannerItems: [
        { image:  require('@/assets/banner.jpg') },
      ],
      stats: [
        { title: '已留资', value: '11131' },
        { title: '进行中', value: '323' },
        { title: '已成交', value: '31' }
      ],
      orderList: [
        {
          name:"测试",
          status:"00",
          orderNo:"1152346487",
          carNumber:"渝A05166",
          applyAmount:80000,
          statusStr:"预审通过",
          area:"重庆-重庆",
          applyTime:"2023-12-09 09:05:02",
        },
        {
          name:"测试2",
          status:"00",
          orderNo:"1234565487",
          applyAmount:60000,
          carNumber:"渝D05168",
          statusStr:"预审通过",
          area:"四川-成都",
          applyTime:"2023-12-09 09:05:02",
        },
        {
          name:"测试2",
          status:"00",
          orderNo:"1234565487",
          applyAmount:60000,
          carNumber:"渝D05168",
          statusStr:"预审通过",
          area:"四川-成都",
          applyTime:"2023-12-09 09:05:02",
        },
        {
          name:"测试2",
          status:"00",
          orderNo:"1234565487",
          applyAmount:60000,
          carNumber:"渝D05168",
          statusStr:"预审通过",
          area:"四川-成都",
          applyTime:"2023-12-09 09:05:02",
        },
        {
          name:"测试2",
          status:"00",
          orderNo:"1234565487",
          applyAmount:60000,
          carNumber:"渝D05168",
          statusStr:"预审通过",
          area:"四川-成都",
          applyTime:"2023-12-09 09:05:02",
        }
      ]
    };
  },
  methods: {

    // 订单页面
    orderpPage() {
      this.$router.push(`/order`);
    },
    goInviteBroker(){
      this.$router.push(`/invite`);
    },
    goPromoteCustomer(){
      this.$router.push(`/product`);
    },


  }
};
</script>

<style >
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: #39a9ed;
}


.promotion-link img {
  width: 100%;
  margin-bottom: 20px;
}




.child {
  padding: 3px 6px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  border:1px solid #2396F5;
  color: #2396F5;
}

.child:hover{

  background:#2396F5;
  color: #ffffff;
}
.p_16_0 .van-badge--fixed{
  right: 7px !important;
}

</style>

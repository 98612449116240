<template>
  <div class="login-container">

    <div class="login-card">
      <van-form class=" m_t_8">

        <van-field v-model="phone" maxlength="11"  left-icon="phone-o" type="tel"  placeholder="请输入账号" ></van-field>
        <van-field
          v-model="code"
          center
          type="number"
          left-icon="eye-o"
          maxlength="6"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button size="small" type="primary" @click="sendCode"  style="width: 86px;" :disabled="countdown>0">{{countdown?countdown+'s':'发送验证码'}}</van-button>
          </template>
        </van-field>

        <van-button type="info"  block native-type="submit"  @click="onSubmit">登陆</van-button>
      </van-form>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
const moment = window.moment;
import { oauthTokenPost } from '@/api/index';

export default {
  components: {},
  data() {
    return {
      phone: '',
      code: '',
      countdown: 0,
    };
  },
  computed: {},
  mounted() {
    const u = JSON.parse(localStorage.userInfo || '{}');

    //
    // // 有用户信息 且没过期 就直接前往
    // if (u.token && moment().isBefore(u.expiresTime)) {
    //   this.$router.replace('/index');
    // }

    //
  },

  methods: {
    sendCode() {
      // 模拟发送验证码的异步操作
      if (this.validatePhone()) {
        Toast.success('验证码已发送');
        this.startCountdown();
      } else {
        Toast.fail('请输入正确的手机号');
      }
    },
    validatePhone() {
      // 简单的手机号验证逻辑，你可以根据实际需求调整
      const phoneRegex = /^1[0-9]{10}$/;
      return phoneRegex.test(this.phone);
    },
    startCountdown() {
      this.countdown = 60;
      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    onSubmit() {
      if (this.validatePhone() && this.code !== '') {
        // 执行登录逻辑
        Toast.success('登录成功');
      } else {
        Toast.fail('请填写正确的手机号或验证码');
      }
    },
  },
};
</script>
<style scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url('../../assets/d3148bd6742da6598200d24b4966ba9f.png') no-repeat center center fixed;
  background-size: cover;
}

.login-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.login-logo {
  margin: 0 4.267vw;
}


/* 自定义Vant组件的样式 */
.van-field {
  margin-bottom: 20px;
}



</style>

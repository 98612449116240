<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <van-nav-bar
      title="产品列表"
      left-arrow
      @click-left="goBack"
      @click-right="share"
      fixed
    >
      <template #right>

        <span class="col_2396F5 font_bold" >我要推广
        <van-icon class="col_2396F5" size="18px" name="share" />
        </span>
      </template>
    </van-nav-bar>

    <!-- 基本信息 -->
    <div class="m_t_64">
      <van-cell-group   inset >
        <van-card
          style="background: #FFF; margin: 16px 0 10px 0;"
          :thumb="form.productLogoUrl || '-' "
          :thumb-link="'/product/detail?id='+form.id"
        >

          <template #title>
            <div class="font_bold font_14">{{form.productName}}</div>
          </template>
          <template #desc>
            <p class="text_ind_2 col_666">{{form.productIntroduce}}</p>
          </template>

          <template #num>
            <van-tag   size="mini" class="selected-tag" mark   type="danger">返</van-tag>
            <span class="col_FF5252 font_14 m_t_16">{{form.productRebate}}%</span>

          </template>
        </van-card>
      </van-cell-group>
    </div>

    <!-- 产品说明 -->

      <!-- 标题 -->
      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        产品说明
      </van-divider>


      <div>

        <van-cell-group   inset >
          <van-cell :title="item.label" :value="item.value"
                    v-for="(item, index) in [
            {
              label: '贷款额度',
              value: form.loanLimit ? `${form.loanLimit / 10000}万` : '-',
            },
            {
              label: '月利率',
              value: form.interestRate ? `${form.interestRate}%` : '-',
            },
            {
              label: '还款说明',
              value: form.repaymentDescription || '-',
            },
            {
              label: '贷款期限',
              value: form.applyLoanTermStr ? `${form.applyLoanTermStr}期` : '-',
            },
            {
              label: '产品类型',
              value: form.productTypeStr || '-',
            },
          ]"
                    :key="index">

          </van-cell>
        </van-cell-group>


      </div>

    <!-- 申请条件 -->
      <!-- 标题 -->
      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        申请条件
      </van-divider>


      <div>

        <van-cell-group   inset >
          <van-cell :title="item.label" :value="item.value"
                    v-for="(item, index) in [
            {
              label: '年龄条件',
              value: `${form.minimumAgeCondition || ''}-${
                form.maximumAgeCondition || ''
              }周岁`,
            },
            {
              label: '受理范围',
              value: form.acceptanceScope || '-',
            },
            {
              label: '征信要求',
              value: form.creditRequirements || '-',
            },
            {
              label: '实名要求',
              value: form.realNameRequirements || '-',
            },
          ]"
                    :key="index">

          </van-cell>
        </van-cell-group>


      </div>




    <!-- 分享图片弹窗 -->
    <van-popup v-model="shareDialogShow">
      <div class="w_88_vw">
        <van-swipe>
          <van-swipe-item v-for="(item, index) in shareImgSrcArr" :key="index">
            <img
              class="dis_flex"
              :src="item"
              @click="shareDialogShow = false"
            />
          </van-swipe-item>
        </van-swipe>
      </div>

      <!--  -->
    </van-popup>

    <!-- 画布 -->
    <canvas
      class="pos_fixed l_200_vw t_0"
      ref="myCanvas"
      :width="shareImgWidth"
      :height="shareImgHeight"
    />

    <!-- 二维码 -->
    <VueQr
      v-if="shareQrcodeText"
      class="pos_fixed l_200_vw t_0"
      ref="qrCode"
      :text="shareQrcodeText"
      :callback="qrCallback"
    />

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import VueQr from 'vue-qr';

import mixin from '@/mixin/index';


export default {
  mixins: [mixin],
  components: {
    VueQr,
  },
  data() {
    return {
      id: null,

      userInfo: {},
      officialAccountInfo: {},

      form: {
        id:123456,
        productLogoUrl: "",
        productImageUrl:"http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220629/gXQTlhYdDtbQPkUYOvLT.jpg",
        productName: "测试产品",
        productIntroduce:"测试产品撒发顺丰是的发生水岸东方水电费水岸东方水岸东方阿斯蒂芬测试产品撒测试产品方水岸东方阿斯蒂芬测试产品撒测试产品撒测试产品撒测试产品撒发顺丰是的发生水岸东方水电费水岸东方水岸东方阿斯蒂芬",
        productRebate: 35,
        maximumAmount: 300000,
        applyLoanTermStr: "36,24",
        interestRate: 25,
        productTypeStr: "类型",
      },

      /**
       * 分享 弹窗
       */
      shareDialogShow: false,
      shareQrcodeText: null,
      shareImgWidth: 1242,
      shareImgHeight: 2208,
      shareImgBgSrcArr: [],
      shareImgSrcArr: [],
      shareDialogForm: {},

      //
    };
  },
  computed: {},
  mounted() {
    const query = this.$route.query;
    if (!query.id) {
      alert('缺少必须传参');
      return;
    }
    this.id = query.id;

    if (localStorage.userInfo) {
      const u = JSON.parse(localStorage.userInfo);
      this.userInfo = u;
    }

    this.getOfficialAccountInfo();

    this.getDetail();

    //
  },
  methods: {
    // 生成分享图片
    async createShareImg(qrcodeSrc, bgSrc) {
      const c = this.$refs['myCanvas'];
      const ctx = c.getContext('2d');

      let dataUrl;
      try {
        Toast.loading({
          duration: 10 * 1000,
          forbidClick: true,
          message: '请稍后..',
        });

        // 背景
        const bg_img = new Image();
        bg_img.setAttribute('crossOrigin', 'Anonymous');
        bg_img.src = bgSrc.replace(/http:/g, 'https:');
        await new Promise((resolve) => {
          bg_img.onload = () => resolve();
        });
        ctx.drawImage(bg_img, 0, 0, this.shareImgWidth, this.shareImgHeight);

        // 二维码
        if (!qrcodeSrc) Toast('获取二维码图片异常');
        const qr_code_img = new Image();
        qr_code_img.src = qrcodeSrc;
        await new Promise((resolve) => {
          qr_code_img.onload = () => resolve();
        });
        ctx.drawImage(qr_code_img, 872, 1872, 320, 320);

        // 公众号名称
        ctx.font = 'bold 40px YaHei';
        ctx.fillText(this.officialAccountInfo.officialAccountName, 232, 1740);

        // 产品名称
        ctx.font = 'bold italic 72px SimHei';
        ctx.fillStyle = '#000';
        ctx.fillText(this.shareDialogForm.productName, 88, 1960);

        // 姓名+电话
        ctx.font = '56px YaHei';
        ctx.fillText(
          `${this.userInfo.brokerName} ${this.userInfo.brokerMobile}`,
          88,
          2072
        );

        // 长按二维码
        ctx.restore();
        ctx.fillStyle = '#666';
        ctx.font = '40px YaHei';
        ctx.fillText('长按二维码或微信扫一扫', 88, 2156);

        // 画布转图片
        dataUrl = c.toDataURL('image/png');

        //
      } catch (err) {
        // 埋点
        window.aplus_queue.push({
          action: 'aplus.record',
          arguments: [
            'pages_product_detail_create_poster_err',
            'CLK',
            {
              msg: JSON.stringify({
                action: '【生成图片异常】',
                url: window.location.href,
                ua: window.navigator.userAgent,
                brokerName: this.userInfo.brokerName,
                openId: this.userInfo.openId,
                err: err.toString(),
              }),
            },
          ],
        });

        //
      } finally {
        Toast.clear();
      }

      return dataUrl;

      //
    },

    // 二维码生成回调
    async qrCallback(v) {
      this.shareQrcodeText = null;
      let len = this.shareImgBgSrcArr.length;
      const recue = async (i) => {
        const src = await this.createShareImg(v, this.shareImgBgSrcArr[i]);
        this.shareImgSrcArr.push(src);
        if (i < len - 1) recue(++i);
      };
      await recue(0);
    },

    // 分享
    async share() {
      const invalidCode = await this.createQrInvalid(1);

      const host = ['development', 'uat'].includes(process.env.NODE_ENV)
        ? 'http://incoming.yqzcm.com'
        : 'https://huoke.jjr.dwzjkj.com';


      this.shareImgBgSrcArr = [
        `${this.form.productImageUrl}?tsp=${new Date().getTime()}`,
      ];

      this.shareImgSrcArr = [];
      this.shareQrcodeText =
        `${host}/` +
        `?productNo=${this.form.productId || ''}` +
        `&brokerId=${this.userInfo.brokerId || ''}` +
        `&officialAccountKey=${
          this.officialAccountInfo.officialAccountKey || ''
        }` +
        `&invalidCode=${invalidCode}`;
      this.shareDialogForm = this.form;
      this.shareDialogShow = true;
    },

    // 获取 详情
    async getDetail() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          forbidClick: true,
          message: '请稍后..',
        });

        // this.form = await nationalBrokerProfileIdProductDetails(this.id);

        Toast.clear();

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 获取 公众号信息
    async getOfficialAccountInfo() {
  /*    this.officialAccountInfo = await brokerGetOfficialAccountInfoPost({
        officialAccountKey: this.userInfo.officialAccountKey,
      });*/
    },


    goBack() {
      this.$router.go(-1);
    },

    //
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9"},[_c('van-nav-bar',{attrs:{"title":"产品列表","left-arrow":"","fixed":""},on:{"click-left":_vm.goBack,"click-right":_vm.share},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('span',{staticClass:"col_2396F5 font_bold"},[_vm._v("我要推广 "),_c('van-icon',{staticClass:"col_2396F5",attrs:{"size":"18px","name":"share"}})],1)]},proxy:true}])}),_c('div',{staticClass:"m_t_64"},[_c('van-cell-group',{attrs:{"inset":""}},[_c('van-card',{staticStyle:{"background":"#FFF","margin":"16px 0 10px 0"},attrs:{"thumb":_vm.form.productLogoUrl || '-',"thumb-link":'/product/detail?id='+_vm.form.id},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"font_bold font_14"},[_vm._v(_vm._s(_vm.form.productName))])]},proxy:true},{key:"desc",fn:function(){return [_c('p',{staticClass:"text_ind_2 col_666"},[_vm._v(_vm._s(_vm.form.productIntroduce))])]},proxy:true},{key:"num",fn:function(){return [_c('van-tag',{staticClass:"selected-tag",attrs:{"size":"mini","mark":"","type":"danger"}},[_vm._v("返")]),_c('span',{staticClass:"col_FF5252 font_14 m_t_16"},[_vm._v(_vm._s(_vm.form.productRebate)+"%")])]},proxy:true}])})],1)],1),_c('van-divider',{style:({ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' })},[_vm._v(" 产品说明 ")]),_c('div',[_c('van-cell-group',{attrs:{"inset":""}},_vm._l(([
          {
            label: '贷款额度',
            value: _vm.form.loanLimit ? `${_vm.form.loanLimit / 10000}万` : '-',
          },
          {
            label: '月利率',
            value: _vm.form.interestRate ? `${_vm.form.interestRate}%` : '-',
          },
          {
            label: '还款说明',
            value: _vm.form.repaymentDescription || '-',
          },
          {
            label: '贷款期限',
            value: _vm.form.applyLoanTermStr ? `${_vm.form.applyLoanTermStr}期` : '-',
          },
          {
            label: '产品类型',
            value: _vm.form.productTypeStr || '-',
          },
        ]),function(item,index){return _c('van-cell',{key:index,attrs:{"title":item.label,"value":item.value}})}),1)],1),_c('van-divider',{style:({ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' })},[_vm._v(" 申请条件 ")]),_c('div',[_c('van-cell-group',{attrs:{"inset":""}},_vm._l(([
          {
            label: '年龄条件',
            value: `${_vm.form.minimumAgeCondition || ''}-${
              _vm.form.maximumAgeCondition || ''
            }周岁`,
          },
          {
            label: '受理范围',
            value: _vm.form.acceptanceScope || '-',
          },
          {
            label: '征信要求',
            value: _vm.form.creditRequirements || '-',
          },
          {
            label: '实名要求',
            value: _vm.form.realNameRequirements || '-',
          },
        ]),function(item,index){return _c('van-cell',{key:index,attrs:{"title":item.label,"value":item.value}})}),1)],1),_c('van-popup',{model:{value:(_vm.shareDialogShow),callback:function ($$v) {_vm.shareDialogShow=$$v},expression:"shareDialogShow"}},[_c('div',{staticClass:"w_88_vw"},[_c('van-swipe',_vm._l((_vm.shareImgSrcArr),function(item,index){return _c('van-swipe-item',{key:index},[_c('img',{staticClass:"dis_flex",attrs:{"src":item},on:{"click":function($event){_vm.shareDialogShow = false}}})])}),1)],1)]),_c('canvas',{ref:"myCanvas",staticClass:"pos_fixed l_200_vw t_0",attrs:{"width":_vm.shareImgWidth,"height":_vm.shareImgHeight}}),(_vm.shareQrcodeText)?_c('VueQr',{ref:"qrCode",staticClass:"pos_fixed l_200_vw t_0",attrs:{"text":_vm.shareQrcodeText,"callback":_vm.qrCallback}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>

  <div>

    <van-cell-group   :border="false">
    <van-cell
      style="padding: 5px 16px;"
      :border="false"
    >
      <template #title >
        <span class="font_bold font_16">{{item.name}} </span>
        <van-tag  size="large" class="selected-tag" plain  v-if="item.carNumber" type="warning">{{item.carNumber}}</van-tag>
      </template>

      <template #default>
        <van-tag  size="large" class="selected-tag"  type="success">{{item.statusStr}}</van-tag>
      </template>

    </van-cell>
    <van-cell :border="false"  v-for="(item, index) in params.filter((it) => it)"
              :key="index"   style="padding: 5px 16px;"  >

      <template #title>{{item.label}}</template>
      <template #default><span class="col_333">{{item.value}}</span> </template>

    </van-cell>
      <van-cell :border="false"  style="padding: 5px 16px;" >

        <template #title></template>
        <template #default>
          <van-button plain size="small" @click="detail" type="info">查看</van-button>
        </template>
      </van-cell>

      <div  class="m_t_16" ></div>
      <van-divider  v-if="bor_solid"   />
    </van-cell-group>
  </div>
</template>

<script>
const moment = window.moment;

export default {
  props: {
    item: Object,
    bor_solid: Boolean,
  },
  data() {
    return {
     params: [
       {
         label: '申请金额：',
         value: this.item.applyAmount || '-' ,
       },
       {
         label: '区域：',
         value: this.item.area || '-' ,
       },
       {
         label: '时间：',
         value: this.item.applyTime || '-',
       },


  ]
    };
  },
  computed: {},
  created() {},
  methods: {
    moment,

    // 查看
    detail() {

      const o = this.$props.item;
      this.$router.push(`/order/detail?orderNo=${o.orderNo || ''}`);
      this.$emit('handleDetail');
    },

    //
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <van-nav-bar
      title="订单列表"
      left-arrow
      @click-left="goBack"
    />


    <van-search
      v-model="where.keyword"
      show-action
      clearable
      placeholder="请输入客户\手机号"
    >
      <template #action >
        <span class="m_r_8"   @click="showPopup = true"><van-icon name="filter-o" /> 状态</span>
      </template>
    </van-search>

    <!---->
    <van-popup v-model="showPopup"  position="bottom"  >
      <van-nav-bar
        :title="'请选择状态'"
        left-text="取消"
        right-text="确认"
        @click-left="togglePopup"
        @click-right="onConfirm"
      />

      <div style="height: 40vh;overflow: scroll">

            <van-checkbox-group v-model="selectedOptionIds"  >
              <van-cell-group>
                <van-cell
                  v-for="(option,index) in options"
                  clickable
                  :key="option.value"
                  @click="toggle(index,option)"
                >

                  <template #title >
                    <span>{{option.label}}</span>
                  </template>

                  <template #right-icon>
                    <van-checkbox  :name="option.value" ref="checkboxes" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-checkbox-group>
      </div>
    </van-popup>



    <!-- 上下拉 -->
    <div ref="pullRefresh" class="flex_1 over_y_auto h_1 ">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="list.length ? '没有更多了' : null"
          @load="onLoad"
        >

            <!-- 项 -->
            <ItemOrder
              v-for="(item, index) in list"
              :key="index"
              :item="item"
              :bor_solid="false"
              class="m_0_16 m_t_16 bor_rad_8 bor_solid_2_fff bg_col_fff"
              @handleDetail="
              () => {
                window.sessionStorage.purchasePageNum = pageNum;
                window.sessionStorage.purchaseScrollTop =
                  $refs['pullRefresh'].scrollTop;
              }
            "
            />

          <!-- 缺省 -->
          <van-empty  v-if="!list.length" description="暂无订单信息" class="empty-info" />

          <!--  -->
        </van-list>
      </van-pull-refresh>

      <div class="h_56"></div>
      <!--  -->
    </div>

    <BottomTabBar/>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { saleGoods } from '@/api/index';
import BottomTabBar from "@/components/BottomTabBar.vue";


export default {
  components: {
    BottomTabBar,
    ItemOrder: () => import('@/components/ItemOrder.vue'),
  },
  data() {
    return {
      checked:false,
      orderCar:[],
      where: {},
      showPopup: false,
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
      ],
      selectedOptions: [],
      selectedOptionIds:[],
      list: [
        {
          name:"测试",
          status:"00",
          orderNo:"1152346487",
          carNumber:"渝A05166",
          applyAmount:80000,
          statusStr:"预审通过",
          area:"重庆-重庆",
          applyTime:"2023-12-09 09:05:02",
        },
        {
          name:"测试2",
          status:"00",
          orderNo:"1234565487",
          applyAmount:60000,
          carNumber:"渝D05168",
          statusStr:"预审通过",
          area:"四川-成都",
          applyTime:"2023-12-09 09:05:02",
        },
        {
          name:"测试2",
          status:"00",
          orderNo:"1234565487",
          applyAmount:60000,
          carNumber:"渝D05168",
          statusStr:"预审通过",
          area:"四川-成都",
          applyTime:"2023-12-09 09:05:02",
        },
        {
          name:"测试2",
          status:"00",
          orderNo:"1234565487",
          applyAmount:60000,
          carNumber:"渝D05168",
          statusStr:"预审通过",
          area:"四川-成都",
          applyTime:"2023-12-09 09:05:02",
        },
        {
          name:"测试2",
          status:"00",
          orderNo:"1234565487",
          applyAmount:60000,
          carNumber:"渝D05168",
          statusStr:"预审通过",
          area:"四川-成都",
          applyTime:"2023-12-09 09:05:02",
        }
      ],

      pageNum: 1,
      pageSize: 10,

      /**
       * 上下拉刷新
       */
      refreshing: false,
      loading: false,
      finished: false,
    };
  },
  mounted() {
    //获取数据
    this.getList({
      pageNum: 1,
      pageSize: sessionStorage.purchasePageNum
        ? +sessionStorage.purchasePageNum * this.pageSize
        : undefined,
    }).then(() => {
      this.$nextTick(() => {
        // 恢复刚才浏览的位置
        if (sessionStorage.purchaseScrollTop) {
          this.$refs['pullRefresh'].scrollTop = sessionStorage.purchaseScrollTop;
          this.pageNum = +sessionStorage.purchasePageNum;
          sessionStorage.removeItem('purchaseScrollTop');
          sessionStorage.removeItem('purchasePageNum');
        }

        //
      });

      //
    });
  },
  methods: {
    // 上拉加载
    onLoad() {
      this.getList({ pageNum: parseInt(this.pageNum) + 1 });
    },

    // 下拉刷新
    onRefresh() {
      this.getList({ pageNum: 1 });
    },

    //隐藏或显示商品列表
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    onConfirm(){
      this.onRefresh();
      this.togglePopup();
    },
    //选择状态
    toggle(index,option) {

      // 移除采购列表中的商品逻辑
      const i = this.selectedOptionIds.findIndex(x => x === option.value);
      if(i !== -1){
        this.selectedOptionIds.splice(i, 1);
      }else {
        this.selectedOptionIds.push(option.value);
      }
      this.changeChecked(option);
    },

    changeChecked(option){

      // 移除采购列表中的商品逻辑
      const index = this.selectedOptions.findIndex(x => x.value === option.value);
      if(index !== -1){
        this.selectedOptions.splice(index, 1);
      }else {
        this.selectedOptions.push(option);
      }
    },


    // 获取 列表
    async getList({ pageNum = 1, pageSize = this.pageSize }) {

      console.log(this.selectedOptionIds)
      try {
      /*  if (pageNum == 1) {
          Toast.loading({
            duration: 10 * 1000,
            message: '请稍后..',
            forbidClick: true,
          });
        }

        const data = await saleGoods({
          currentPage: pageNum,
          pageSize: pageSize || this.pageSize
        });
        Toast.clear();
        this.refreshing = this.loading = false;

        const len = data.rows.length;

        this.list =
          pageNum == 1 ? data.rows : [...this.list, ...data.rows];
        this.finished = len < pageSize;
        this.pageNum =
          this.list.length > 0
            ? Math.ceil(this.list.length / this.pageSize)
            : 1;*/

        //
      } catch (err) {
        this.refreshing = this.loading = false;
        Toast(err);
      }

      //
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>

</style>

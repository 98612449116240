

import { Toast } from 'vant';

import {
  profileTypeGenerateQrInvalidPost,
} from '@/api/index';

// 全局混入
export default {
  data() {
    return {
    };
  },
  computed: {

  },
  watch: {

  },
  methods: {
    // 生成二维码有效信息
    async createQrInvalid(type) {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        const data = await profileTypeGenerateQrInvalidPost(type);
        Toast.clear();

        return data;

        //
      } catch (err) {
        Toast(err);
      }

      return null;

      //
    },

    // 
  }
};

<template>
  <div class="h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <van-nav-bar
      title="银行卡绑定"
      left-arrow
      @click-left="goBack"
    />
    <!-- 背景 -->
    <van-image
      class="w_100_per dis_flex"
      :src="require('@/assets/bindcard.png')"
    />

    <!-- 收缩 -->
    <div class="m_b_neg_32"></div>

    <!-- 块 -->
    <div class="pos_rel bg_col_fff bor_rad_8 over_hid p_16 m_0_16 m_t_neg_48">
      <!-- 标题 -->
      <div class="dis_flex ali_it_cen">
        <div class="flex_1 font_18 font_bold">上传身份证照</div>
        <div class="font_12 col_666">请上传清晰照片，四角齐全</div>
      </div>

      <!-- 上传身份证 -->
      <div class="dis_flex m_t_16">
        <div class="flex_1">
          <UploadFile
            class="h_24_vw"
            :src="
              form.certImgFront ||
              'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220613/CXcbuAGKvxlxBDKtjoHC.png'
            "
            :isOnlyImg="true"
            :isReturnFile="true"
            @handleUpload="(file) => OCR('front', file)"
          />

          <div class="dis_flex ju_con_cen font_14 col_666 m_t_8">
            点击上传人像面
          </div>

          <!--  -->
        </div>

        <div class="flex_1 m_l_16">
          <UploadFile
            class="h_24_vw"
            :src="
              form.certImgBack ||
              'http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220613/AvpweDIiRKHbhUpeoHVW.png'
            "
            :isOnlyImg="true"
            :isReturnFile="true"
            @handleUpload="(file) => OCR('back', file)"
          />

          <div class="dis_flex ju_con_cen font_14 col_666 m_t_8">
            点击上传国徽面
          </div>

          <!--  -->
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 块 -->
    <div class="pos_rel bg_col_fff bor_rad_8 over_hid m_0_16 m_t_16">
      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">身份证号</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.certNo"
          type="text"
          placeholder="请输入"
          maxlength="18"
        />

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">账户名称</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.bankAccountName"
          type="text"
          placeholder="请输入"
          maxlength="30"
        />

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">银行账号</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.bankAccount"
          type="text"
          placeholder="请输入"
          maxlength="30"
        />

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="flex_1 font_14">银行名称</div>
        <div
          :class="`font_14 ${
            bankList.find((it) => it.value == form.interBankNo) ? '' : 'col_777'
          } m_l_16`"
          @click="form = { ...form, bankDialogShow: true }"
        >
          {{
            (bankList.find((it) => it.value == form.interBankNo) || {}).label ||
            '请选择'
          }}
        </div>

        <!--  -->
      </div>

      <!-- 银行选择 弹窗 -->
      <PickEnum
        :show.sync="form.bankDialogShow"
        :columns="bankList"
        @handleConfirm="
          (v) =>
            (form = {
              ...form,
              bankName: v.label,
              interBankNo: v.value,
            })
        "
      />

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16">
        <div class="font_14">银行预留手机号</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.reservedMobile"
          type="text"
          placeholder="请输入"
          maxlength="11"
          readonly
          @input="
            (e) => {
              let v = e.currentTarget.value;
              v = (v || '').replace(/[^0-9]/g, '');
              form = { ...form, reservedMobile: v };
            }
          "
        />

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 提交 -->
    <div class="dis_flex m_0_16 m_t_32">
      <van-button class="flex_1 font_16" type="info" @click="submit()"
        >提交</van-button
      >
    </div>

    <!-- 留白 -->
    <div class="h_56"></div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import { isCardId, isMobile } from '@/utils/tools';

import {
  nationalBrokerProfileBindBankCardPost,
  loanIncomingPartsIdCardOneSidePost,
  nationalBrokerProfileBankCardInfo,
  brokerProfileBankSupportList,
} from '@/api/index';

export default {
  components: {
    UploadFile: () => import('@/components/UploadFile'),
    PickEnum: () => import('@/components/PickEnum'),
  },
  data() {
    return {
      form: {},

      bankList: [],

      //
    };
  },
  computed: {},
  mounted() {
    this.getBankList();

    this.getDetail();

    //
  },
  methods: {
    // 提交
    async submit() {
      try {
        if (!this.form.certImgFront) {
          throw '请上传身份证人像面';
        }

        if (!this.form.certImgBack) {
          throw '请上传身份证国徽面';
        }

        if (!isCardId(this.form.certNo)) {
          throw '请输入正确的身份证号';
        }

        if (!this.form.bankAccount) {
          throw '请输入银行账户';
        }

        if (!this.form.bankAccountName) {
          throw '请输入银行账号';
        }

        if (!this.form.bankName) {
          throw '请选择银行';
        }

        if (!isMobile(this.form.reservedMobile)) {
          throw '请输入正确的手机号';
        }

        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        await nationalBrokerProfileBindBankCardPost({
          bankAccount: this.form.bankAccount,
          bankAccountName: this.form.bankAccountName,
          bankName: this.form.bankName,
          interBankNo: this.form.interBankNo,
          certImgFront: this.form.certImgFront,
          certImgBack: this.form.certImgBack,
          certNo: this.form.certNo,
          reservedMobile: this.form.reservedMobile,
        });

        Toast.success({
          message: '提交成功',
          forbidClick: true,
        });

        setTimeout(() => {
          // 2.3.0.2废弃
          // this.$router.go(-1);

          this.$router.replace('/bankList');

          //
        }, 1000);

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // OCR
    async OCR(side, file) {
      let params = new FormData();
      params.append('side', side);
      params.append('file', file);

      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        const data = await loanIncomingPartsIdCardOneSidePost(params);

        Toast.clear();

        let form = {
          ...this.form,
        };
        if (data.citizenIdNumber) form.certNo = data.citizenIdNumber;
        if (data.frontSide) form.certImgFront = data.frontSide;
        if (data.backSide) form.certImgBack = data.backSide;

        this.form = form;

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 获取 详情
    async getDetail() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        this.form = await nationalBrokerProfileBankCardInfo();
        const u = JSON.parse(localStorage.userInfo);
        this.form.reservedMobile=u.brokerMobile;
        Toast.clear();

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 获取 银行
    async getBankList() {
      const data = await brokerProfileBankSupportList();

      this.bankList = data.map((item) => {
        return {
          ...item,
          label: item.bankName,
          value: item.interBankNo,
        };
      });

      //
    },

    goBack() {
      this.$router.go(-1);
    },
    //
  },
};
</script>

<template>
  <div class="h_100_vh dis_flex flex_dir_col">
    <!-- 内容 -->
    <div class="flex_1 over_y_auto h_1 bg_col_F5F7F9">
      <!-- 顶部信息 -->
      <div class="pos_rel">
        <!-- 背景 -->
        <img
          class="w_100_per dis_flex"
          src="@/assets/016d985ba0760aa8012099c8c717f8.jpg@2o.jpg"
          height="120px"
        />


        <div class="pos_abs l_0 t_0 w_100_per">
          <div class="dis_flex ali_it_cen m_0_16 m_t_32">
            <!-- 头像 -->

            <van-icon  size="56" name="user-circle-o" color="#eeeeee" />


            <!-- 内容 -->
            <div class="flex_1 m_l_8">
              <div class="dis_flex ali_it_cen">
                <!-- 名称 -->
                <div class="font_16 col_fff">{{ userInfo.user?userInfo.user.username:"" }}</div>

                <!-- 勋章 -->
                <div class="dis_flex ali_it_cen m_l_8  bg_col_lin_90_F6E8C4_DCB373 ">
<!--                  <img-->
<!--                    class="pos_rel dis_flex m_r_neg_8"-->
<!--                    src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220614/dCSmPTVnrVBrlrrOdBLo.png"-->
<!--                    style="width: 18px; height: 18px"-->
<!--                  />-->

                  <van-icon   color="#DCB373" name="diamond-o" />
                  <div
                    class="font_10 "
                    style="padding: 0 8px 0 5px"
                  >
                    {{userInfo.user?userInfo.user.position:"" }}
                  </div>

                  <!--  -->
                </div>

                <!--  -->
              </div>

              <!-- 手机号 -->
              <div class="font_12 col_fff m_t_8">
                {{ concealPhoneNumb(userInfo.user.mobile?userInfo.user.mobile:"") }}
              </div>

              <!--  -->
            </div>

            <!-- 登出 -->
            <van-image
              class="w_24 h_24 dis_flex"
              :src="require('@/assets/exit.png')"
              @click="logout()"
            />

            <!--  -->
          </div>

          <!--  -->
        </div>

        <!--  -->
      </div>

      <div class="m_t_16"   v-for="(item, index) in paramList" :key="index">
        <van-cell-group  inset>
          <van-cell  :icon="item1.iconUrl" :class="item1.color" :title="item1.label"  title-class="font_218" is-link  v-for="(item1, index1) in item" :key="index1" v-show="!item1.hide"
                     @click="itemClick(item1)">

<!--            <template #icon>-->
<!--              <van-icon  :name="item1.iconUrl"  class="font_218"  style="color: #f00; "></van-icon>-->
<!--            </template>-->

          </van-cell>

        </van-cell-group>
      </div>

      <!-- 留白 -->
      <div class="h_56"></div>

      <!--  -->
    </div>

    <!-- 底部导航 -->
    <BottomTabBar />


    <!-- 分享图片弹窗 -->
    <van-popup v-model="shareDialogShow">
      <div class="w_80_vw">
        <van-swipe>
          <van-swipe-item v-for="(item, index) in shareImgSrcArr" :key="index">
            <img class="dis_flex" :src="item" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <!--  -->
    </van-popup>

    <!-- 画布 -->
    <canvas
      class="pos_fixed l_200_vw t_0"
      ref="myCanvas"
      :width="shareImgWidth"
      :height="shareImgHeight"
    />

    <!-- 二维码 -->
    <VueQr
      v-if="shareQrcodeText"
      class="pos_fixed l_200_vw t_0"
      ref="qrCode"
      :text="shareQrcodeText"
      :callback="qrCallback"
    />


    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import VueQr from 'vue-qr';
import { columnAmount, concealPhoneNumb } from '@/utils/tools';

import mixin from '@/mixin/index';


import {
  authRevokeDelete,
} from '@/api/index';

export default {

  mixins: [mixin],
  components: {
    BottomTabBar: () => import('@/components/BottomTabBar.vue'),
    VueQr,
  },
  data() {
    return {
      userInfo: {user:{userName:"测试",position:"管理员",mobile:"15823336950"},invitationSwitch:'Y',whetherLeader:"GOVERNOR"},
      advConfigList: [],
      myIntegral: 0,
      officialAccountInfo:{officialAccountName:"公众号"},


      paramList: [
       [
          {
            label: '银行卡绑定',
            iconUrl:'card',
            color:'icon_col_0A82E6',
          },
          {
            label: '经纪人管理',
            iconUrl:'manager',
            color:'icon_col_66BB6A',
          //  hide: !['LEADER', 'GOVERNOR'].includes(this.userInfo.whetherLeader),
          },
        ],

        [
          {
            label: '推广客户赚钱',
            iconUrl:'award',
            color:'icon_col_FF5252',
          },
          {
            label: '招募经纪人',
            iconUrl:'bookmark',
            color:'icon_col_A674E3',
       //     hide: !(this.userInfo.invitationSwitch == 'Y'),
          },
          {
            label: '招募城市合伙人',
            iconUrl:'point-gift',
            color:'icon_col_FF7043',
            //   hide: !(this.userInfo.whetherLeader == 'GOVERNOR'),
          },
        ],

        [
          {
            label: '邀请记录',
            iconUrl:'invitation',
            color:'icon_col_2396F5',
          },
          // {
          //   label: '我的协议',
          //   iconUrl:'column',
          //   color:'icon_col_F5A227',
          // },
        ],

        //
      ],

      /**
       * 分享 弹窗
       */
      shareDialogShow: false,
      shareQrcodeText: null,
      shareImgWidth: 1242,
      shareImgHeight: 2208,
      shareImgBgSrcArr: [],
      shareImgSrcArr: [],

      /**
       * 协议
       */
      agreementLeaderUrl:
        'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220707/ohLiBQVOnidkxVRcUEUE.pdf',
      agreementBrokerUrl:
        'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220707/cmPoZaXDzrzLDVcxpaXi.pdf',

    };
  },
  computed: {},
  mounted() {

    // if (localStorage.userInfo) {
    //   const u = JSON.parse(localStorage.userInfo);
    //   this.userInfo = u;
    // }else {
    //   this.$router.replace(`/login`);
    // }

    //
  },
  methods: {
    columnAmount,
    concealPhoneNumb,

    // 生成分享图片
    async createShareImg(qrcodeSrc, bgSrc) {
      const c = this.$refs['myCanvas'];
      const ctx = c.getContext('2d');

      let dataUrl;

      try {
        Toast.loading({
          duration: 10 * 1000,
          forbidClick: true,
          message: '请稍后..',
        });

        // 背景
        const bg_img = new Image();
        bg_img.setAttribute('crossOrigin', 'Anonymous');
        bg_img.src = bgSrc.replace(/http:/g, 'https:');
        await new Promise((resolve) => {
          bg_img.onload = () => resolve();
        });
        ctx.drawImage(bg_img, 0, 0, this.shareImgWidth, this.shareImgHeight);

        // 二维码
        if (!qrcodeSrc) Toast('获取二维码图片异常');
        const qr_code_img = new Image();
        qr_code_img.src = qrcodeSrc;
        await new Promise((resolve) => {
          qr_code_img.onload = () => resolve();
        });
        if (!this.officialAccountInfo.officialAccountName) {
          ctx.drawImage(qr_code_img, 956, 1940, 248, 248);
        } else {
          ctx.drawImage(qr_code_img, 956, 1924, 236, 236);

          // 公众号名称
          ctx.textAlign = 'center';
          ctx.font = 'bold 32px YaHei';
          ctx.fillText(
            this.officialAccountInfo.officialAccountName || '',
            1076,
            2196
          );
        }
        // 画布转图片
        dataUrl = c.toDataURL('image/png');

        //
      } catch (err) {
        console.log(err);
        // 埋点
        window.aplus_queue.push({
          action: 'aplus.record',
          arguments: [
            'pages_center_create_poster_err',
            'CLK',
            {
              msg: JSON.stringify({
                action: '【生成图片异常】',
                url: window.location.href,
                ua: window.navigator.userAgent,
                brokerName: this.userInfo.brokerName,
                openId: this.userInfo.openId,
                err: err.toString(),
              }),
            },
          ],
        });

        //
      } finally {
        Toast.clear();
      }

      return dataUrl;

      //
    },

    // 二维码生成回调
    async qrCallback(v) {
      this.shareQrcodeText = null;
      let len = this.shareImgBgSrcArr.length;
      const recue = async (i) => {
        const src = await this.createShareImg(v, this.shareImgBgSrcArr[i]);
        this.shareImgSrcArr.push(src);
        console.log("----------22222---")
        if (i < len - 1) recue(++i);
      };
      await recue(0);
    },

    // 招募城市合伙人
    async recruitGovernor() {
      const invalidCode = await this.createQrInvalid(2);

      let shareImgBgSrcArr = [
        `http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220630/xWnxzmvHGLUvILpvMqlH.jpg?tsp=${new Date().getTime()}`,
      ];

      /**
       * 广告位 - 城市合伙人推广
       */
   /*   const o = this.advConfigList.find(
        (it) => it.advPos == 'CITY_PARTNER_PROMOTION'
      );
      if (o) {
        shareImgBgSrcArr = o.advPic.split(',');
        shareImgBgSrcArr = shareImgBgSrcArr.map((item) => {
          return `${item}?tsp=${new Date().getTime()}`;
        });
      }*/

      this.shareImgBgSrcArr = shareImgBgSrcArr;
      this.shareImgSrcArr = [];
      const projectHost = ['development', 'uat'].includes(process.env.NODE_ENV)
        ? 'http://jjr.geyaogf.com'
        : 'https://jjr.geyaogf.com';
      //'https://jjr.songcw.com';
      this.shareQrcodeText =
        `${projectHost}/register` +
        `?inviteeId=${this.userInfo.brokerId || ''}` +
        `&whetherLeaderStatus=LEADER` +
        `&officialAccountKey=${this.userInfo.officialAccountKey || ''}` +
        `&invalidCode=${invalidCode || ''}`;
      this.shareDialogShow = true;
    },

    // 分享 - 招募经纪人
    async share() {
      const invalidCode = await this.createQrInvalid(2);

      let shareImgBgSrcArr = [
        `http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220630/AzGiNLlXrAhfUOAvyXEx.jpg?tsp=${new Date().getTime()}`,
      ];

      /**
       * 广告位 - 经纪人推广
       */
  /*    const o = this.advConfigList.find(
        (it) => it.advPos == 'BROKER_PROMOTION'
      );
      if (o) {
        shareImgBgSrcArr = o.advPic.split(',');
        shareImgBgSrcArr = shareImgBgSrcArr.map((item) => {
          return `${item}?tsp=${new Date().getTime()}`;
        });
      }*/

      this.shareImgBgSrcArr = shareImgBgSrcArr;
      this.shareImgSrcArr = [];
      const projectHost = ['development', 'uat'].includes(process.env.NODE_ENV)
        ? 'http://jjr.geyaogf.com'
        : 'https://jjr.geyaogf.com';
      this.shareQrcodeText =
        `${projectHost}/register` +
        `?inviteeId=${this.userInfo.brokerId || ''}` +
        `&officialAccountKey=${this.userInfo.officialAccountKey || ''}` +
        `&invalidCode=${invalidCode || ''}`;
      this.shareDialogShow = true;
    },

    // 登出
    logout() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '您是否确认登出？',
        })
        .then(async () => {
          try {
            Toast.loading({
              duration: 10 * 1000,
              message: '请稍后..',
              forbidClick: true,
            });

            await authRevokeDelete();

            Toast.clear();

            localStorage.removeItem('userInfo');
            this.$router.replace(`/login`);

            //
          } catch (err) {
            Toast(err);
          }

          //
        })
        .catch(() => {});

      //
    },

    // 项 点击
    async itemClick(item) {
      switch (item.label) {
        case '银行卡绑定':
     /*     Toast.loading({
            duration: 10 * 1000,
            message: '请稍后..',
            forbidClick: true,
          });

          nationalBrokerProfileBankCardInfo().then((data) => {
            Toast.clear();

            if (Object.keys(data).length) {
              this.$router.push('/bankList');
            } else {
              this.$router.push('/bindcard');
            }

            //
          });*/
          this.$router.push('/bankList');
          break;

        case '经纪人管理':
          switch (this.userInfo.whetherLeader) {
            case 'GOVERNOR':
              this.$router.push('/governor');
              break;
            case 'LEADER':
              this.$router.push('/brokerManage');
              break;
          }
          break;

        case '我的积分':
          this.$router.push('/myIntegral');
          break;

        case '推广客户赚钱':
          this.$router.push('/product');
          break;

        case '招募经纪人':
          this.share();
          break;

        case '招募城市合伙人':
          this.recruitGovernor();
          break;

        case '邀请记录':
          this.$router.push('/invite');
          break;

        case '我的协议':
          // 2.3.4.1废弃
          /* this.$router.push(
            `/pdfView?url=${
              this.userInfo.whetherLeader == 'LEADER'
                ? this.agreementLeaderUrl
                : this.agreementBrokerUrl
            }&title=${
              this.userInfo.whetherLeader == 'LEADER'
                ? '城市合伙人协议'
                : '经纪人协议'
            }`
          ); */

          if (this.userInfo.whetherLeader == 'LEADER') {
            window.location.href = `/assets/pages/leaderAgreement/index.html`;
          } else {
            window.location.href = `/assets/pages/brokerAgreement/index.html`;
          }
          break;
      }

      //
    },




    //
  },
};
</script>
<style>

</style>

import * as http from '@/utils/http';



// 登出
export const authRevokeDelete = async () => {
  const res = await http.get(`/user/logout`);
  return res?.data;
};


// 登录
export const oauthTokenPost = async (data) => {

  const res = await http.post(`/user/login`, data);
  return res || {};
};

// 统计今日采购额、昨日采购额、本月采购额、今年采购额
export const buyStatistics = async () => {
  const res = await http.get(`/depotHead/getBuyStatistics`);
  return res?.data || {};
};


// 统计采购的总金额
export const buyPrice = async () => {
  const res = await http.get(`/depotItem/buyPrice`);
  return res?.data || {};
};


//采购清单列表
export const saleGoods = async (data) => {
  const res = await http.get('/depotItem/saleGoods', data);
  return res?.data || {};
};

//根据条码查询商品信息
export const  materialByBarCode = async (data) =>  {
  const res = await http.get(`/material/getMaterialByBarCode`,data);
  return res?.data || {};
};
//查询商品下拉选择框
export const  findBySelect = async (data) =>  {
  const res = await http.get(`/material/findBySelect`,data);
  return res || {};
};

//添加采购信息
export const  addDepotHeadAndDetail = async (data) =>  {
  const res = await http.post('/depotHead/addDepotHeadAndDetail',data);
  return res?.data || {};
};


//修改采购信息
export const  updateDepotHeadAndDetail = async (data) =>  {
  const res = await http.put(`/depotHead/updateDepotHeadAndDetail`,data);
  return res?.data || {};
};


/**
     * 获取供应商列表
     *
     */
export const  supplierList = async () =>  {
  const res = await http.post(`/supplier/findBySelect_sup`);
  return res || {};
};


/**
 * 获取订单编号
 * @param data
 * @returns {Promise<any|{}>}
 */
export const  buildNumber = async () =>  {
  const res = await http.get("/sequence/buildNumber");
  return res?.data || {};
};



// 采购记录
export const depotHeadList = async (data) => {
  const res = await http.get(`/depotHead/list`,data);
  return res?.data || {};
};

// 获取采购详情
export const detailByNumber = async (data) => {
  const res = await http.get(`/depotHead/getDetailByNumber`,data);
  return res?.data  || {};
};


//获取采购商品详情列表
export const depotItemDetailList = async  (data) =>{
  const res = await http.get('/depotItem/getDetailList', data);
  return res?.data  || {};
}



//审核
export const batchSetStatus = async  (data) =>{
  const res = await http.post('/depotHead/batchSetStatus', data);
  return res?.data  || {};
}



//获取账户
export const getAccount = async  () =>{
  const res = await http.get('/account/getAccount');
  return res?.data  || {};
}

//获取仓库
export const findDepotByCurrentUser = async  () =>{
  const res = await http.get('/depot/findDepotByCurrentUser');
  return res?.data  || {};
}




export const showContract = async  () =>{
  const res = await http.get('/contract/get/file');
  return res || {};
}

<template>
  <div class="dis_flex bg_col_fff ">
    <!-- 导航栏 -->
    <van-tabbar v-model="currentRoutePath" fixed>
      <van-tabbar-item v-for="tab in tabArr"  :icon="tab.icon" :name="tab.path" :to="tab.path" >{{tab.label}}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem} from 'vant';

export default {
  components: {
    VanTabbar: Tabbar,
    VanTabbarItem: TabbarItem,
  },
  props: {},
  data() {
    return {
      currentRoutePath: null,
      //
    };
  },
  computed: {
    tabArr: () => [
      {
        label: '首页',
        icon: 'home-o',
        path: '/index',
      },
      {
        label: '订单',
        icon:'orders-o',
        path: '/order',
      },
      {
        label: '我的',
        icon: 'manager-o',
        path: '/profile',
      },
    ],

    //
  },
  created() {

    this.currentRoutePath = this.$route.path;
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>

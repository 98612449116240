<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
  <van-nav-bar
    title="产品列表"
    left-arrow
    @click-left="goBack"
  />
    <!-- 上下拉 -->
    <van-pull-refresh
      class="h_100_vh over_y_auto bg_col_F5F7F9"
      v-model="refreshing"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :immediate-check="false"
        :finished="finished"
        :finished-text="list.length ? '没有更多了' : '暂无数据'"
        @load="onLoad"
      >
        <div class="m_t_16"
             v-for="(item, index) in list"
             :key="index">
        <van-cell-group  inset >
        <van-card
          style="background: #FFF; margin: 16px 0;"
          :thumb="item.productLogoUrl || '-' "
          :thumb-link="'/product/detail?id='+item.id"
        >

          <template #title>
            <div class="font_bold font_14">{{item.productName}}</div>
          </template>
          <template #tags>

            <van-tag   v-for="(tag, i) in [
                {
                  label: '最高额度',
                  value: item.maximumAmount
                    ? `${item.maximumAmount / 10000}万`
                    : '-',
                },
                {
                  label: '最高期限',
                  value: item.applyLoanTermStr
                    ? `${Math.max(...item.applyLoanTermStr.split(','))}期`
                    : '-',
                },
                {
                  label: '最低月利率',
                  value: item.interestRate ? `${item.interestRate}%` : '-',
                },
                {
                  label: '产品类型',
                  value: item.productTypeStr || '-',
                },
              ]" :key="i" plain  size="medium" class="selected-tag"  type="primary">{{ tag.label }}: {{tag.value}}</van-tag>
          </template>

          <template #price>
            <van-tag   size="mini" class="selected-tag" mark   type="danger">返</van-tag>
            <span class="col_FF5252 font_14 m_t_16">{{item.productRebate}}%</span>

          </template>

          <template #footer>
            <div class="m_t_16">
            <van-button size="small" plain  type="info" @click="share(item)" > 分享 </van-button>
            <van-button size="small" type="info" @click="detail(item)"  > 详情 </van-button>
            </div>
          </template>
        </van-card>

        </van-cell-group>
        </div>

        <!--  -->
      </van-list>
    </van-pull-refresh>

    <!-- 分享图片弹窗 -->
    <van-popup v-model="shareDialogShow">
      <div class="w_88_vw">
        <van-swipe>
          <van-swipe-item v-for="(item, index) in shareImgSrcArr" :key="index">
            <img
              class="dis_flex"
              :src="item"
              @click="shareDialogShow = false"
            />
          </van-swipe-item>
        </van-swipe>
      </div>

      <!--  -->
    </van-popup>

    <!-- 画布 -->
    <canvas
      class="pos_fixed l_200_vw t_0"
      ref="myCanvas"
      :width="shareImgWidth"
      :height="shareImgHeight"
    />

    <!-- 二维码 -->
    <VueQr
      v-if="shareQrcodeText"
      class="pos_fixed l_200_vw t_0"
      ref="qrCode"
      :text="shareQrcodeText"
      :callback="qrCallback"
    />

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import VueQr from 'vue-qr';
//
import mixin from '@/mixin/index';

import {
  brokerGetOfficialAccountInfoPost,
} from '@/api/index';

export default {
  mixins: [mixin],
  components: {
    VueQr,
  },
  data() {
    return {
      userInfo: {},
      officialAccountInfo: {},

      /**
       * 列表
       */
      list: [{
        id:123456,
        productLogoUrl: "",
        productImageUrl:"http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220629/gXQTlhYdDtbQPkUYOvLT.jpg",
        productName: "测试产品",
        productRebate: 35,
        maximumAmount: 300000,
        applyLoanTermStr: "36,24",
        interestRate: 25,
        productTypeStr: "类型",
      },{
        id:123456,
        productLogoUrl: "",
        productImageUrl:"http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220629/gXQTlhYdDtbQPkUYOvLT.jpg",
        productName: "测试产品",
        productRebate: 35,
        maximumAmount: 300000,
        applyLoanTermStr: "36,24",
        interestRate: 25,
        productTypeStr: "类型",
      }],

      /**
       * 上下拉刷新
       */
      refreshing: false,
      loading: false,
      finished: false,

      /**
       * 分享 弹窗
       */
      shareDialogShow: false,
      shareQrcodeText: null,
      shareImgWidth: 1242,
      shareImgHeight: 2208,
      shareImgBgSrcArr: [],
      shareImgSrcArr: [],
      shareDialogForm: {},

      //
    };
  },
  computed: {},
  mounted() {
    if (localStorage.userInfo) {
      const u = JSON.parse(localStorage.userInfo);
      this.userInfo = u;
    }

    this.getOfficialAccountInfo();

    this.getList();

    //
  },
  methods: {

    goBack() {
      this.$router.go(-1);
    },
    // 生成分享图片
    async createShareImg(qrcodeSrc, bgSrc) {
      const c = this.$refs['myCanvas'];
      const ctx = c.getContext('2d');

      let dataUrl;
      try {
        Toast.loading({
          duration: 10 * 1000,
          forbidClick: true,
          message: '请稍后..',
        });

        // 背景
        const bg_img = new Image();
        bg_img.setAttribute('crossOrigin', 'Anonymous');
        bg_img.src = bgSrc.replace(/http:/g, 'https:');
        await new Promise((resolve) => {
          bg_img.onload = () => resolve();
        });
        ctx.drawImage(bg_img, 0, 0, this.shareImgWidth, this.shareImgHeight);

        // 二维码
        if (!qrcodeSrc) Toast('获取二维码图片异常');
        const qr_code_img = new Image();
        qr_code_img.src = qrcodeSrc;
        await new Promise((resolve) => {
          qr_code_img.onload = () => resolve();
        });
        ctx.drawImage(qr_code_img, 872, 1872, 320, 320);

        // 公众号名称
        ctx.font = 'bold 40px YaHei';
        ctx.fillText(this.officialAccountInfo.officialAccountName, 232, 1740);

        // 产品名称
        ctx.font = 'bold italic 72px SimHei';
        ctx.fillStyle = '#000';
        ctx.fillText(this.shareDialogForm.productName, 88, 1960);

        // 姓名+电话
        ctx.font = '56px YaHei';
        ctx.fillText(
          `${this.userInfo.brokerName} ${this.userInfo.brokerMobile}`,
          88,
          2072
        );

        // 长按二维码
        ctx.restore();
        ctx.fillStyle = '#666';
        ctx.font = '40px YaHei';
        ctx.fillText('长按二维码或微信扫一扫', 88, 2156);

        // 画布转图片
        dataUrl = c.toDataURL('image/png');

        //
      } catch (err) {
        // 埋点
        window.aplus_queue.push({
          action: 'aplus.record',
          arguments: [
            'pages_product_create_poster_err',
            'CLK',
            {
              msg: JSON.stringify({
                action: '【生成图片异常】',
                url: window.location.href,
                ua: window.navigator.userAgent,
                brokerName: this.userInfo.brokerName,
                openId: this.userInfo.openId,
                err: err.toString(),
              }),
            },
          ],
        });

        //
      } finally {
        Toast.clear();
      }

      return dataUrl;

      //
    },

    // 二维码生成回调
    async qrCallback(v) {
      this.shareQrcodeText = null;
      let len = this.shareImgBgSrcArr.length;
      const recue = async (i) => {
        const src = await this.createShareImg(v, this.shareImgBgSrcArr[i]);
        this.shareImgSrcArr.push(src);
        if (i < len - 1) recue(++i);
      };
      await recue(0);
    },

    // 分享
    async share(item) {
      const invalidCode = await this.createQrInvalid(1);

      const host = ['development', 'uat'].includes(process.env.NODE_ENV)
        ? 'http://incoming.yqzcm.com'
        : 'https://huoke.jjr.dwzjkj.com';

      this.shareImgBgSrcArr = [
        `${item.productImageUrl}?tsp=${new Date().getTime()}`,
      ];

      this.shareImgSrcArr = [];
      this.shareQrcodeText =
        `${host}/` +
        `?productNo=${item.productId || ''}` +
        `&brokerId=${this.userInfo.brokerId || ''}` +
        `&officialAccountKey=${
          this.officialAccountInfo.officialAccountKey || ''
        }` +
        `&invalidCode=${invalidCode}`;
      this.shareDialogForm = item;
      this.shareDialogShow = true;
    },

    // 详情
    detail(item) {
      this.$router.push(`/product/detail?id=${item.id}`);
    },

    // 上拉加载
    onLoad() {},

    // 下拉刷新
    onRefresh() {
      this.getList();
    },

    // 获取 列表
    async getList() {
      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

      //  const data = await nationalBrokerProfileProductList();
        Toast.clear();
        this.refreshing = this.loading = false;
        this.finished = true;

       // this.list = data;

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 获取 公众号信息
    async getOfficialAccountInfo() {
    /*  this.officialAccountInfo = await brokerGetOfficialAccountInfoPost({
        officialAccountKey: this.userInfo.officialAccountKey,
      });*/
    },

    //
  },
};
</script>
<style>
.selected-tags {
  margin: 10px 15px; /* 添加下边距，避免标签和搜索框内容重叠 */
}
</style>
